.container-notification {
  position: abosoute;
  top: 0;
  .popover-inner {
    background: #1d253e;
    // padding: 20px 0px;
    border-radius: 6px;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
    color: #fff;
  }
  .box-noti {
    width: 340px;
    background: #1d253e;
    border-radius: 6px;

    .con-header {
      display: flex;
      flex-direction: row;
      padding: 15px 20px;

      .txt-noti-header {
        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
        margin-right: 5px;
        color: white;
      }
    }

    .con-item {
      cursor: pointer;
      padding: 7px 20px;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        .icon-remove {
          opacity: 1;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }

      .txt-author {
        font-weight: bold;
      }

      .txt-project{
        text-decoration: underline;
        cursor: pointer;
        font-weight: bold;
      }

      .con-content {
        flex: 1;
        margin: 0 10px;
        line-height: 19px;
      }

      .dot-unread {
        width: 9px;
        height: 9px;
        background: #c8247f;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 4px;
      }

      .txt-time {
        color: #8f9bb3;
        opacity: 0.8;
        font-size: 12px;
        line-height: 16px;
      }

      .icon-remove {
        opacity: 0;
      }
    }

    .txt-no-noti {
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      opacity: 0.8;
    }
  }
  .txt-desc {
    max-height: 57px;
    overflow: hidden;
  }
  > .popover {
    max-width: 1000px;
    z-index: 1031;
    left: 30px !important;
    border: none !important;
  }
  .noti-label {
    font-size: 17px;
    font-weight: bold;
    // padding: 30px 30px;
  }
  .noti-category {
    display: flex;
    justify-content: space-between;
  }
  .noti-mark-all {
    &:hover {
      text-decoration: underline;
    }
    cursor: pointer;
  }
  .noti-line {
    height: 1px;
    width: 100%;
    background-color: #aeaeae;
    box-shadow: 1px 1px 1px #242424;
    margin-top: 5px;
  }
  .noti-category {
    margin-top: 10px;
  }
}

.con-number-noti {
  width: 12px;
  height: 12px;
  background: #C8247F;
  position: absolute;
  border-radius: 6px;
  left: 8px;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}

#noti-popup {
  cursor: pointer;
  position: relative;
  width: 20px;
}
