@font-face {
  font-family: "avenir";
  font-weight: normal;
  font-style: normal;
  src: url("./files/Avenir.ttc") format("truetype");
}

@font-face {
  font-family: "avenir next";
  font-weight: normal;
  font-style: normal;
  src: url("./files/Avenir Next.ttc") format("truetype");
}

@font-face {
  font-family: "avenir next";
  font-weight: bolder;
  font-style: normal;
  src: url("./files/Avenir Next Condensed.ttc") format("truetype");
}
