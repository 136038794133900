
.tooltip-inner{
  font-size: 0.7em !important;
}


.btn-disable {
  pointer-events: none;
  color: gray;
}

.input-error:focus {
  border: 1px solid red !important;
}
