@import '../variables';

#box-search-replace {
  padding: 0 30px;
  position: relative;

  .input-search {
    position: relative;
    display: flex;
    align-items: center;

    input {
      background: $simon-body-app-color;
      outline: none;
      border: 1px solid #3B4776;
      width: 100%;
      border-radius: 4px;
      height: 40px;
      color: #FFF;
      padding: 0 32px;
    }

    .prefix-icon {
      cursor: pointer;
      position: absolute;
      left: 0;
      padding: 0 8px;

      &:hover {
        opacity: 0.8;
      }
    }
  
    .suffix-icon {
      cursor: pointer;
      position: absolute;
      right: 0;
      padding: 0 8px;
      color: $simon-highlight-color;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
