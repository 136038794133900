.email__container {
  width: 100%;
  height: 100%;
  background: #13162c;

  .email__form {
    background: #1d253e;
    padding: 30px;
    color: white;
    font-size: 20px;
    border-radius: 5px;

    .form-check-input {
      margin-top: 0.6rem;
    }
  }
}
