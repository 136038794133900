@import '../mixin/scrollbar';

.dashboard-box {
  @include main-scroll-bar();

  background: #1d253e;
  border: 5px solid #1d253e;
  color: #fff;
  width: 100%;
  border-radius: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100% - 80px);
  min-height: 260px;

  h2 {
    font-size: 1.3em;
    margin: 0.75rem;
  }

  hr {
    border-color: #272e49;
  }

  table {
    position: relative;
    thead {
      th {
        color: #FFF;
        font-weight: normal;
        border: none;
        position: sticky;
        top: -8px;
        background: #1d253e;
        padding-bottom: 8px;
      }
    }
    tr {
      border-bottom: 1px solid #272e49;
  
      td {
        border: 0;
        color: #fff;
        vertical-align: baseline;
      }
    }
  }

  a {
    color: #fff;

    &:hover {
      color: rgba(255, 255, 255, 0.8);
      text-decoration: none;
    }
  }
  
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dashboard-filter {
      &__button {
        background: transparent;
        border: none;
        outline: none;

        &:focus, 
        &:hover,
        &:active {
          background: transparent !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }

      &__item {
        &.active {
          background: #272e49;
        }

        &:focus, 
        &:active {
          background: transparent !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }

  .project {
    &-info {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      font-size: calc(12px + (16 - 12) * ((100vw - 300px) / (1600 - 300)));

      &:hover {
        .project-tools {
          opacity: 1;
        }
      }
    }
    
    &-name {
      display: flex;
      align-items: center;

      &__thumb {
        width: 52px;
        box-sizing: border-box;
        margin: 0 8px;

        img {
          width: 52px;
          height: 30px;
          border-radius: 5px;
          margin-bottom: 4px;
          object-fit: cover;
        }
      }

      &__text {
        flex: 1;
      }
    }

    &-tools  {
      opacity: 0;
    }
  }
}

.dropdown-menu {
  &.show {
    background: #1d253e;
    color: #fff;
  }

  .dropdown-item {
    color: #fff;

    &:focus,
    &:hover {
      background: #272e49;
      color: #fff;
    }
  }
}
