@import '../variables';

.modal-confirm {
  .modal-content {
    background: $simon-main-color;
    color: #fff;
    border: none;
  }

  &__header {
    border: none !important;
  }

  &__body {
    border: none !important;
  }

  &__footer {
    border: none !important;

    .btn-ok {
      background: none;
      border: none;
      outline: none;
      box-shadow: none;

      &:focus, &:active {
        background: none !important;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }

    .btn-cancel {
      background: none;
      border: none;
      outline: none;
      box-shadow: none;

      &:focus, &:active {
        // background: #330450 !important;
        background: none !important;
        border: none;
        outline: none;
        box-shadow: none !important;
      }
    }

    .btn-cancel, 
    .btn-ok {
      &.active  {
        background: #330450 !important;
      }
    }
  }
}
