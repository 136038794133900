$sidebar-bar-color: #a58fb3;
$main-bar-color: #8f9bb3;
$scroll-bar-width: 4px;

@mixin sidebar-scroll-bar() {
  &::-webkit-scrollbar {
    width: $scroll-bar-width;
  }


  &::-webkit-scrollbar-thumb {
    background-color: $sidebar-bar-color;
    border-radius: 12px;
  }

  scrollbar-color: $sidebar-bar-color rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

@mixin main-scroll-bar() {
  &::-webkit-scrollbar {
    width: $scroll-bar-width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $main-bar-color;
    border-radius: 12px;
  }
  
  scrollbar-color: $main-bar-color rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}
