.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    font-family: "Gotham";
    font-weight: 300;
}

.btn-simon {
  background-color: rgb(79, 32, 109);
  border: 0px;
}
.btn-simon:hover, .btn-simon:focus {
  background-color: rgb(62, 28, 83);
}
.btn-simon:not(:disabled):not(.disabled).active, .btn-simon:not(:disabled):not(.disabled):active, .show > .btn-simon.dropdown-toggle {
  background-color: rgb(62, 28, 83);
}
.loading-transcript {
  text-align: center;
  font-size: 1.2em;
}