@import '../variables';
@import '../mixin/scrollbar';

#navigation {
  .navbar {
    .logo {
      height: 32px;
    }
  }

  .bg-dark {
    background-color: #272e49 !important;
  }

  .navbar-brand {
    font-size: 1rem;
  }

  .navbar {
    padding: 0px 1rem;

    .nav-item {
      margin: auto 10px;
      color: #fff;
    }
  }

  .navbar-dark {
    .navbar-text {
      color: #fff;
    }
  }

  button {
    height: 30px;
    font-size: 0.8em;
  }

  .save-now {
    padding: 0;
    height: 20px;
  }

  .visitors {
    position: relative;
    display: inline-flex; 
    justify-content: flex-end;
    align-items: center;

    &__avatar {
      position: relative;
      top: 0;
      left: 0;
      cursor: pointer;
      height: 24px;
      width: 24px;

      &:hover {
        z-index: 100 !important;
        border-radius: 50%;
        box-shadow: 0 0 0 2px #C8247F;
        background: #8f9bb3;
      }

      &:not(:first-child) {
        margin-left: -4px;
      }

      img {
        height: 24px;
        width: 24px;
        object-fit: contain;
        border-radius: 50%;
      }
    }

    &__avatar-holder {
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      top: 1px;
      left: 0;

      cursor: pointer;
      background: #8f9bb3;
      height: 26px;
      width: 26px;
      border-radius: 50%;
      margin-left: -4px;
      z-index: 9;
      font-size: 13px;

      &:hover {
        border-radius: 50%;
        box-shadow: 0 0 0 2px #C8247F;
        background: #8f9bb3;
      }
    }
  }
}

.timecode-popover {
  &__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__body {
    background-color: $simon-body-app-color !important;
    width: 160px;
    padding: 16px !important;
    margin-top: 4px;
    font-size: 14px;
    border-radius: 12px;
    border: 1px solid #ffffff50;
    user-select: none;

    input {
      width: 100%;
      outline: none;
      height: 24px;
      border: none;
      border-radius: 4px;
      background: #1d253e;
      color: #fff;

      &:focus {
        border: 1px solid $simon-highlight-color;
      }
    }

    .dropdown {
      .dropdown-menu {
        padding: 0px !important;

        .dropdown-item {
          padding: 2px 8px;

          &:hover {
            background-color: #ffffff05 !important;
          }
        }
      }

      button {
        background-color: transparent !important;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 0;
        font-size: 14px;

        &:focus {
          outline: none !important;
          border: none !important;
          box-shadow: none !important;
        }
      }
    }
  }

  &__label {
    color: $simon-highlight-color !important;
    font-size: 14px;
    font-weight: bold;
  }

  &__resolution {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      // padding: 0 4px;

      label {
        margin: 0;
        color: #fff;
      }

      input {
        padding: 0;
      }
    }
  }

  &__btn-confirm {
    cursor: pointer;
    color: $simon-highlight-color;
    padding: 0 4px;
  }

  &__btn-cancel {
    cursor: pointer;
    color: gray;
  }
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

.user-picture {
  height: 24px;
  border-radius: 50%;
}

#exportButton {
  min-width: 120px;
}

.visitors__popover {
  @include main-scroll-bar();

  position: relative;
  top: 1px;
  border: 1px solid #ffffff10;
  background: $simon-body-app-color;
  color: #fff;
  padding: 8px;
  width: 150px;
  min-height: 200px;
  max-height: 200px;
  font-size: 12px;
  overflow-y: scroll;

  .visitor-popover {
    display: flex; 
    align-items: center;
    margin-top: 8px;

    &__avatar {
      img {
        height: 24px;
        width: 24px;
      }
    }

    &__fullname {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;    
    }
  }
}
