.popover {
  &__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
  }
}
