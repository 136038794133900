$simon-main-color: #4f206d !default;

$simon-main-medium-color: #52307c !default;

$simon-main-light-color: #bfacce !default;

$simon-main-super-light-color: #d7d0dd !default;

$simon-link-hover-color: #4b2b71 !default;

$simon-highlight-color: #C8247F !default;

$simon-body-app-color: #272E49 !default;
$simon-body-app-second-color: #212529 !default;

$simon-slider-collapsed-width: 50px;
$simon-slider-width: 250px;
$simon-tool-box-height: 50px;

$simon-body-modal-color: #1D253E !default;

:root {
  --body-color: #{$simon-body-app-color};
  --main-color: #{$simon-main-color};
  --sider-width: #{$simon-slider-width};
  --sider-collapsed-width: #{$simon-slider-collapsed-width};
}
