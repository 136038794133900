@import '../variables';

#modal-share {
  max-width: 768px;

  strong {
    color: #fff;
  }
  small {
    color: #C8247F;
  }
  
  hr {
    background: #8F9BB330;
    margin: 12px 0;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }

  .modal-body {
    background-color: $simon-body-modal-color;
    color: #8F9BB3;
    border-radius: 12px;
    padding: 2rem;
  }

  .form-share {

    .top-bar {
      display: flex;
      * {
        font-size: 13px;
      }
      justify-content: space-between;
      padding: 12px 0;

      &__dropdown {
        .dropdown {
          border: none;
          outline: none;
        }

        .dropdown-menu {
          border: 1px solid #ffffff30;
        }

        .dropdown-item {
          padding: 8px;
          font-size: 12px;

          &.active {
            background: #ffffff07;
          }

          &:hover {
            background: #ffffff07;
          }
        }

        button {
          padding: 0;
          background: transparent;
          border: none;
          outline: none;

          &:focus {
            outline: none;
            border: none;
            box-shadow: none;
          }
        }
      }
    }

    .el-input {
      display: flex;
      button {
        border: none;
        outline: none;
        background: #C8247F;
        color: #fff;
        height: 48px;
        width: 135px;
        margin: 0 0 0 12px;
        border-radius: 4px;

        &:disabled {
          background: #ffffff30;
        }
      }
      input, textarea {
        width: 100%;
        background: transparent;
        border:  1px solid #8F9BB330;
        outline: none;
        border-radius: 4px;
        color: #FFF;
        height: 48px;
        padding: 0 12px;

        &:disabled, &:read-only {
          background: #ffffff10;
        }

        &::placeholder {
          color: #ffffff50;
        }
      }

      > div {
        position: relative;
        display: flex;
        align-items: center;
        
        > span {
          position: absolute;
          right: 12px;
        }
      }
    }

    table.existing-shares {
      color: #8F9BB3;
      ;
      margin: 1rem 0;
      font-size: 13px;
      td {
        border: 0;
        .dropdown {
          text-align: right;
          vertical-align: middle;
        }
      }
      button {
        font-size: 13px;
      }
    }
  }
}
