@import './fonts/avenir/index.scss';
@import './fonts/gotham/index.scss';

html,
body {
  height: 100vh;
  width: 100vw;  
  overflow: hidden;
  * {
    &:not(.fa) {
      font-family: 'avenir', 'Gotham', sans inherit !important
    }
  }
}
