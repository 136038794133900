@import '../variables';
@import '../mixin/scrollbar';
@import '../mixin/selection';

.transcript-container {
  @include main-scroll-bar();
  @include selection-color();

  height: 100%;
  // position: relative;
  overflow-y: scroll;
  padding: 30px 10px 0 10px;

  .speaker-container {
    .popover-inner {
      max-height: 200px;
      overflow-y: auto;
    }
    .speaker {
      color: #b189d5;
      font-size: 10px;
      cursor: pointer;
      &:hover:not(.disabled) {
        opacity: 0.8;
      }
      &.default-speaker {
        font-weight: bold;
        &:hover:not(.disabled) {
          color: #5a5a5a;
        }
        display: table-cell;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        position: relative;
        top: 3px;
        text-align: center;
      }
      &.disabled {
        cursor: default;
      }
    }
    i {
      display: table-cell;
      vertical-align: middle;
      padding-left: 5px;
    }
  }

  .transcription {
    &-row {
      // @include transition(background 85ms ease-in, border-color 85ms);

      opacity: 1;
      margin: 0;
      padding: 10px 30px 5px 26px;
      // border-left: 4px solid #ffffff;
      position: relative;

      .inner-toolbar {
        .bookmark-icon {
          color: #f9a21d;
        }

        .notes-icon {
          color: #0eacdf;
          display: table-cell;
          width: 20px;
          height: 20px;
          vertical-align: middle;
          position: relative;
          text-align: center;
          top: 3px;

          // @include tablets {
          //   top: 2px;
          // }
        }

        &.inner-toolbar-left {
          left: 51px;
          top: 10px;
        }

        &.inner-toolbar-right {
          float: right;
        }

        > span {
          visibility: hidden;
          color: $simon-main-color;
          cursor: pointer;

          &.visible {
            visibility: visible;
            transition: visibility 0s linear 98ms, opacity 300ms;
          }
        }
      }

      &:hover {
        .inner-toolbar {
          .bookmark-icon,
          .notes-icon:not(.notes-icon-read-only) {
            visibility: visible;
            transition: visibility 0s linear 98ms, opacity 300ms;
          }
        }
      }

      &:last-child {
        padding-bottom: 5px;
      }

      &.playing {
        border-left-color: $simon-main-color;
        .transcription-time {
          color: #333;
        }
      }

      &.bookmarked {
        background: rgba(249, 142, 29, 0.2);
      }
    }

    &-time {
      margin-bottom: 5px;
      display: inline-block;
      color: #b0c0c5;
      font-size: 12.5px;
      cursor: pointer;
      &:hover {
        color: #98a6aa;
      }
      padding-left: 0;
      padding: 0 4px;
      background: #8F9BB310;
      border-radius: 4px;
      height: 18px;
      box-sizing: border-box;
      // @include only-small-devices {
      //   font-size: 11px;
      // }
    }

    &-speaker {
      display: inline-block;
      height: 18px;
      padding: 0 4px;
      background: #C8247F20;
      border-radius: 4px;
      color: #C8247F;
      margin: 0 8px;
      box-sizing: border-box;
    }

    &-text {
      opacity: 1;
      color: #777;
      // @include only-small-devices {
      //   color: #000000;
      // }
      font-size: 16px;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 20px;
      text-align: left;
      &:hover {
        cursor: default;
        &:not(.disabled) {
          cursor: text;
          color: #000000;
        }
      }
      &:focus {
        opacity: 1;
        &:not(.disabled) {
          color: #000000;
        }
      }
      // @include all-mobile-devices {
      //   font-size: 14px;
      // }

      // @include only-small-devices {
      //   font-size: 12px;
      // }
    }

    &-top-bar {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  }
}

.annotation {
  textarea.form-control {
    &:hover {
      overflow: auto !important;
    }
  }
}

.speaker-table {
  background: white;
  border-collapse: separate;
  border-spacing: 0 4px;
  td {
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;
    vertical-align: middle;
    &:last-child {
      padding-left: 5px;
      input {
        margin-top: 5px;
        max-width: 120px;
        &:hover {
          background: #edeff0;
        }
      }
    }
    &.speaker-initials-td {
      cursor: pointer !important;
      // .speaker-initials {
      //   @include circle(26px);
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   background: #edeff0;
      //   color: #000;
      //   text-align: center;
      //   font-size: 10px;
      // }
    }
  }
}

// ------------------------------------------------------------------

.z-index0 {
  z-index: 0 !important;
}

.right-to-left-text {
  direction: rtl !important;
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-rtl {
  direction: rtl !important;
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  direction: ltr !important;
}

.search-and-replace-highlight {
  background: #ffff01;
  color: #000;
}

.current-search-and-replace-highlight {
  background: #ffff01;
  color: #000;
  padding: 4px;
  border-radius: 4px;
}
