@font-face {
  font-family: "Gotham";
  font-weight: bold;
  font-style: normal;
  src: url("./files/Gotham-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Gotham";
  font-style: italic;
  font-weight: normal;
  src: url("./files/Gotham-BookItalic.otf") format("opentype");
}

@font-face {
  font-family: "Gotham";
  font-weight: 100 200 300 400;
  font-style: normal;
  src: url("./files/Gotham-Book.otf") format("opentype");
} 
