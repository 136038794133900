body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
#__next {
  height: 100%;
}
.alert {
  z-index:5000;
  margin:80px 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.invisible-input {
  outline: none;
  color: #ffffff;
  font-weight: 300;
  border: 0;
  background: none;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.invisible-input:hover {
  background-color: rgba(255,255,255,0.1);
}
.renameable {
  position: absolute;
  z-index: 100;
}
#canvas {
  padding: 42px 0 0 64px;
  height:100%;
  background: #13162c;
}
#canvas .row {
  height: 100%;
}
#canvas [class*="col-"] {
  height: 100%;
}